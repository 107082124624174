import { createSelector } from 'reselect';
import { getInsurances } from './getInsurances';
import { type ReduxStateShared } from '~shared/types';

const getInsuranceID = (_: ReduxStateShared, insuranceID?: number | null) => insuranceID;

/**
 * TODO: replace with useGetInsuranceByID, which should fetch an insurance by ID
 * rather than read insurances from the redux store
 */
export const getInsuranceByID = createSelector([getInsurances, getInsuranceID], (insurances, insuranceID) => {
  return insurances.find(({ id }) => id === insuranceID);
});
